<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col cols="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form"  @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" >
                      <ValidationProvider name="Name (En)" vid="name" rules="required">
                      <b-form-group
                        label-cols-sm="3"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.name')}} {{$t('globalTrans.enn')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        plain
                        v-model="form.name"
                        id="name"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                      <b-form-group
                        label-cols-sm="3"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.name')}} {{$t('globalTrans.bnn')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        plain
                        v-model="form.name_bn"
                        id="name_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Profession Type" vid="profession_type" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="profession_type"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('elearning_iabm.profession_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="form.profession_type"
                            :disabled="form.id ? true : false"
                            :options="professionTypeList"
                            id="profession_type"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                      <ValidationProvider v-if="form.profession_type == 1" name="Organization" vid='org_id' rules="required|min_value:1">
                      <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.organization')"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="form.org_id"
                            :options="orgProfileList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider v-if="form.profession_type == 2 || form.profession_type == 3" name="Organization Name (En)" vid="org_name_en" rules="required">
                      <b-form-group
                        label-cols-sm="3"
                        label-for="org_name_en"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.organization')}} {{$t('globalTrans.enn')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        plain
                        v-model="form.other_org_name"
                        id="org_name_en"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                      </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider v-if="form.profession_type == 2 || form.profession_type == 3" name="Organization Name (Bn)" vid="org_name_bn" rules="required">
                      <b-form-group
                        label-cols-sm="3"
                        label-for="org_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.organization')}} {{$t('globalTrans.bnn')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        plain
                        v-model="form.other_org_name_bn"
                        id="org_name_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-form-input>
                      <div class="invalid-feedback">
                            {{ errors[0] }}
                      </div>
                      </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Designation (En)" vid="designation_en" rules="required" v-slot="{ errors }">
                      <b-form-group
                        label-cols-sm="3"
                        label-for="designation_en">
                        <template v-slot:label>
                          {{ $t('globalTrans.designation') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="designation_en"
                          v-model="form.designation_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required" v-slot="{ errors }">
                      <b-form-group
                        label-cols-sm="3"
                        label-for="designation_bn">
                        <template v-slot:label>
                          {{ $t('globalTrans.designation') }} {{ $t('globalTrans.bnn') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="designation_bn"
                          v-model="form.designation_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Mobile" vid="mobile" rules="required|max:11|min:11" v-slot="{ errors }">
                      <b-form-group
                        label-cols-sm="3"
                        label-for="mobile">
                        <template v-slot:label>
                          {{ $t('globalTrans.mobile') }}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="mobile"
                          v-model="form.guest_mobile"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                      </ValidationProvider>
                      <!-- <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors }">
                        <b-form-group
                          label-cols-sm="3"
                          label-for="email">
                          <template v-slot:label>
                            {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="email"
                            v-model="form.email"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider> -->
                      <ValidationProvider name="Photo" vid="image">
                      <b-form-group
                        label-cols-sm="3"
                        class="row"
                        :label="$t('globalTrans.photo')"
                        label-for="image"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{$t('globalTrans.photo')}}
                        </template>
                        <b-form-file
                            id="image"
                            accept="image/*"
                            v-model="form.image"
                            v-on:change="onChangeFile"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-file>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                      </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { guestTrainerStore, guestTrainerUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      errors: [],
      form: {
        name: '',
        name_bn: '',
        designation_en: '',
        designation_bn: '',
        other_org_name: '',
        other_org_name_bn: '',
        profession_type: 1,
        org_id: 1,
        mobile: '',
        image: []
      },
      imageFile: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.form = tmp
    }
  },
  watch: {
    'form.training_type_id': function (newValue) {
        this.trainingCategory = this.categoryList(newValue)
    },
    'form.profession_type': function (newValue) {
      if (!this.form.id) {
        this.getBlank(newValue)
      }
    }
    // 'form.org_id': function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.trainingTypeList = this.getTrainingTypeList(newVal)
    //   } else {
    //     this.trainingTypeList = []
    //   }
    // }
  },
  computed: {
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    orgProfileList () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  methods: {
    getBlank (id) {
      this.form.other_org_name = ''
      this.form.other_org_name_bn = ''
      this.form.org_id = 0
    },
    categoryList (typeId) {
       const category = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return category.filter(item => item.training_type_id === parseInt(typeId))
       }
       return category
    },
    onChangeFile (e) {
      this.imageFile = e.target.files[0]
    },
    getTrainingTypeList (id) {
      const gttl = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === id)
      return gttl
    },
    async saveUpdate () {
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.form).map(key => {
        if (key === 'image') {
          formData.append('image', this.imageFile)
        } else {
          formData.append(key, this.form[key])
        }
      })
      if (this.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${guestTrainerUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, guestTrainerStore, formData, config)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
         this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
